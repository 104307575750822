// --------------------------------------------------------------------------
// Service Worker management and stuff
// --------------------------------------------------------------------------
import * as IDB from 'idb-keyval'

if ('serviceWorker' in navigator) {

    // Register service worker
    navigator.serviceWorker.register('/sw.js').then(registration => {

        // let isLoggedIn = localStorage.getItem('loggedIn') === "1"

        // // if user is logged in, ask for permission and subscribe to push notifications
        // if (isLoggedIn) {
        //     subscribeToNotifications()
        // }

        // Misc lifecycle messages
        if (registration.waiting) {
            console.log('A new service worker is waiting to be installed.')
        }
        if (registration.installing) {
            console.log('A new service worker is being installed...')
        }
        registration.addEventListener('updatefound', () => {
            console.log('A new version of the service worker is available.')
        })

    })

    // receive messages
    navigator.serviceWorker.addEventListener('message', message => {
        console.log("Message received : ", message.data)
    })

    navigator.serviceWorker.addEventListener('controllerchange', () => {
        // This fires when the service worker controlling this page
        // changes, eg a new worker has skipped waiting and become
        // the new active worker.
        console.log("New service worker has taken control")
    });

}

const reloadCache = function() {
    navigator.serviceWorker.register('/sw.js').then(registration => {
        console.log("Reloading SW cache...")
        registration.active.postMessage('reloadCache')
    })
}

async function getPublicKey() {
    return new Promise((resolve, reject) => {
        fetch('/push/key', {
            headers: {
                Accept: 'application/json'
            }
        }).then(response => {
            if (response) {
                response.json().then(json => resolve(json.publicKey)).catch(err => reject(err))
            } else {
                reject(new Error('Could not fetch Vapid Public Key'))
            }
        })
    })
}

async function saveSubscription(user, subscription) {
    await fetch('/push/subscribe', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify({ user, subscription })
    }).then(response => {
        return response.json()
    })
}

function subscribeToNotifications() {
    return navigator.serviceWorker.register('/sw.js').then(async registration => {
        let subscription = await registration.pushManager.getSubscription() // get pushmanager subscription or null
        let permission = await Notification.requestPermission()
        let user = await IDB.get("user")

        console.log(subscription)

        if (permission === "granted" && user) {

            // if this is the first time, save subscription to server and update notificationsEnabled
            if (subscription === null) {
                // get vapid public key from server
                getPublicKey().then(publicKey => {
                    // subscribe with keys
                    return registration.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: publicKey
                    })
                }).then(subscription => {
                    // save PushManager subscription to server
                    return saveSubscription(user, subscription)
                }).then(() => {
                    console.log("Saved subscription to server")

                    // first time only, set notificationsEnabled to true
                    IDB.set('notificationsEnabled', true)
                    return Promise.resolve(permission)
                }).catch(err => {
                    // saving PushManager subscription to server failed
                    return Promise.reject(err)
                })
            } else {
                return Promise.resolve(permission)
            }
        } else {
            return Promise.resolve(permission)
        }
    })
}

export { subscribeToNotifications, reloadCache }