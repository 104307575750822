import QrScanner from "qr-scanner"
import { showErrorToast, showSuccessToast, showCenterToast, showErrorModal, showSuccessModal, showScanResultModal } from "./_global";

const register = function (data) {
    return new Promise((resolve, reject) => {
        fetch("/register/form", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then(function (response) {
            console.log("Response from /register/form : ", response)
            if (response.status === 200) {
                response.json().then(data => {
                    if (data.isFull) {
                        reject("Quelqu'un a été plus rapide et il n'y a plus de places pour cette animation...")
                    } else {
                        console.log("Response from /register/form : ", data)
                        resolve(data)
                    }
                })
            } else {
                reject("Une erreur inconnue s'est produite, réessayez plus tard")
            }
        }).catch(err => {
            console.error(err)
            reject("Aucune réponse du serveur, réessayez plus tard")
        })
    })
}

document.addEventListener('DOMContentLoaded', async () => {

    let bookmarkedEvents = await IDB.get("bookmarkedEvents") || []

    //-----------------------------------------------------------------------
    // QR scan page
    //-----------------------------------------------------------------------
    let scanPage = document.querySelector('#appCapsule.scan')
    let adminScanPage = document.querySelector('#appCapsule.scanner')
    let registrationPage = document.querySelector('#appCapsule.registration')
    let registerForm = document.getElementById('register')
    let registerFormAdmin = document.getElementById('registerAdmin')

    if (scanPage) {

        let currentCamera = 0;
        const openScannerBtn = document.getElementById('openScanner')
        const swapCameraBtn = document.getElementById('swapCamera')
        const scanner = document.getElementById('scanner')
        const scanInfo = document.getElementById('scanInfo')
        const scanEvent = document.getElementById('scanEvent')
        // To enforce the use of the new api with detailed scan results, call the constructor with an options object, see below.
        const qrScanner = new QrScanner(scanner, result => decode(result), { highlightScanRegion: true, highlightCodeOutline: true },
        );

        openScannerBtn.addEventListener('click', () => {
            scanner.style = ""
            qrScanner.start().then(() => { openScannerBtn.remove(); scanInfo.remove(); scanEvent.remove(); swapCameraBtn.style = "" });
        })

        swapCameraBtn.addEventListener('click', () => {
            QrScanner.listCameras(true).then(cameras => {
                if ((currentCamera + 1) === cameras.length) {
                    currentCamera = 0
                } else {
                    currentCamera++
                }
                qrScanner.setCamera(currentCamera)
            })
        })

        function decode(code) {
            let slices = code.data.split('/')
            if (slices) {
                qrScanner.stop()
                let token = slices[slices.length - 1]
                fetch('/register/token', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ token: token })
                }).then(response => {
                    if (response.status === 200) {
                        return response.json()
                    } else { return false }
                }).then(json => {
                    if (json) {
                        showCenterToast("Code correct", 1000, "checkmark-circle")
                        setTimeout(() => {
                            location.href = "/register/form"
                        }, 1000)
                    } else {
                        setTimeout(() => {
                            qrScanner.start()
                        }, 1000)
                    }
                })
            }
        }

    }

    if (adminScanPage) {

        let currentCamera = 0;
        const openScannerBtn = document.getElementById('openScanner')
        const swapCameraBtn = document.getElementById('swapCamera')
        const scanner = document.getElementById('scanner')
        const scanInfo = document.getElementById('scanInfo')
        const scanEvent = document.getElementById('scanEvent')
        // To enforce the use of the new api with detailed scan results, call the constructor with an options object, see below.
        const qrScanner = new QrScanner(scanner, result => decode(result), { highlightScanRegion: true, highlightCodeOutline: true },
        );

        openScannerBtn.addEventListener('click', () => {
            scanner.style = ""
            qrScanner.start().then(() => { openScannerBtn.remove(); scanInfo.remove(); scanEvent.remove(); swapCameraBtn.style = "" });
        })

        swapCameraBtn.addEventListener('click', () => {
            QrScanner.listCameras(true).then(cameras => {
                if ((currentCamera + 1) === cameras.length) {
                    currentCamera = 0
                } else {
                    currentCamera++
                }
                qrScanner.setCamera(currentCamera)
            })
        })

        function decode(code) {
            let slices = code.data.split('/')
            if (slices) {
                qrScanner.stop()
                let token = slices[slices.length - 1]
                fetch('/registration/check', {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ token: token })
                }).then(response => {
                    if (response.status === 200) {
                        return response.json()
                    } else {
                        throw new Error("Who cares")
                    }
                }).then(json => {
                    if (json) {
                        console.log(json)
                        showScanResultModal(`Inscription : <br><strong>${json.firstname} ${json.lastname}</strong><br>Animation :<br> <strong>${json.event.name}</strong><br>${dayjs(json.event.start).format('dddd D MMMM').toLowerCase()}<br>${dayjs(json.event.start).format('HH:mm')} - ${dayjs(json.event.end).format('HH:mm')}`, () => {
                            setTimeout(() => {
                                qrScanner.start()
                            }, 1000)
                        })
                    } else {
                        setTimeout(() => {
                            qrScanner.start()
                        }, 1000)
                    }
                }).catch(err => {
                    qrScanner.stop()
                    showErrorModal("Code invalide", "Erreur", () => {
                        setTimeout(() => {
                            qrScanner.start()
                        }, 1000)
                    })
                })
            }
        }

    }

    if (registerForm) {
        // Login form behavior
        registerForm.addEventListener('submit', function (e) {

            // validation
            if (!registerForm.checkValidity()) {
                e.preventDefault()
                e.stopPropagation()
            } else {
                e.preventDefault()

                const data = Object.fromEntries(new FormData(registerForm).entries())

                // lock form
                let inputs = registerForm.querySelectorAll('input, button');
                [].slice.call(inputs).forEach(input => {
                    input.disabled = true
                    input.classList.add('disabled')
                })


                if (data.firstname.length > 0 && data.lastname.length > 0) {

                    register(data).then(data => {
                        if (data) {

                            let event = data.event;
                            // get bookmarked ids
                            let ids = bookmarkedEvents.flatMap(e => e.id)

                            // user already has bookmark (not possible)
                            if (ids.indexOf(event.id) > -1) {
                                // never
                                console.log("Should not be here")
                                showErrorModal("Erreur inconnue", "Erreur")
                            } else {
                                event.registered = true
                                event.registration = data.registration
                                bookmarkedEvents.push(event)

                                IDB.set('bookmarkedEvents', bookmarkedEvents).then(() => {
                                    location.href = `/registration/${data.registration.token}`
                                }).catch(err => {
                                    console.error(err)
                                    showErrorModal("Une erreur est survenue", "Désolé...", () => {
                                        location.href = "/"
                                    })
                                })
                            }

                        }
                    })
                        .catch(err => {
                            console.error(err)
                            showErrorModal("Une erreur est survenue", "Désolé...", () => {
                                location.href = "/"
                            })
                        })
                }
            }
        })
    }

    if (registerFormAdmin) {
        // Login form behavior
        registerFormAdmin.addEventListener('submit', function (e) {

            // validation
            if (!registerFormAdmin.checkValidity()) {
                e.preventDefault()
                e.stopPropagation()
            } else {
                e.preventDefault()

                const data = Object.fromEntries(new FormData(registerFormAdmin).entries())

                // lock form
                let inputs = registerFormAdmin.querySelectorAll('input, button');
                [].slice.call(inputs).forEach(input => {
                    input.disabled = true
                    input.classList.add('disabled')
                })


                if (data.firstname.length > 0 && data.lastname.length > 0) {

                    register(data).then(data => {
                        if (data) {

                            let event = data.event;
                            // get bookmarked ids
                            let ids = bookmarkedEvents.flatMap(e => e.id)

                            event.registered = true
                            event.registration = data.registration
                            bookmarkedEvents.push(event)


                            location.href = `/registrationadmin/${data.registration.token}`

                        }
                    })
                        .catch(err => {
                            console.error(err)
                            showErrorModal("Une erreur est survenue", "Désolé...", () => {
                                location.href = "/"
                            })
                        })
                }
            }
        })
    }
    if (registrationPage) {
        let qrcode = document.getElementById('registrationQr')
        let code = qrcode.dataset.token
        QRCode.toCanvas(qrcode, code, function (error) {
            if (error) console.error(error)
        })
    }
})