import { showErrorModal, showSuccessModal, showCenterToast } from "./_global"
import { subscribeToNotifications, reloadCache } from "./_swController"
import * as IDB from 'idb-keyval'

document.addEventListener('DOMContentLoaded', () => {
    //-----------------------------------------------------------------------
    // Paramètres
    //-----------------------------------------------------------------------
    let settingsPage = document.querySelector('#appCapsule.settings')
    if (settingsPage) {

        //----------------------------------
        // Remove tickets and reset
        //----------------------------------
        let removeTickets = document.getElementById('removeTickets')
        let removeBookmarks = document.getElementById('removeBookmarks')
        let resetApp = document.getElementById('resetApp')
        let reloadCacheBtn = document.getElementById('reloadCache')

        removeTickets.addEventListener('click', () => {
            IDB.del("tickets")
            showSuccessModal("Les billets ont été supprimés")
        })
        removeBookmarks.addEventListener('click', () => {
            IDB.del("bookmarkedEvents")
            showSuccessModal("Les favoris ont été supprimés")
        })
        reloadCacheBtn.addEventListener('click', () => {
            reloadCache()
        })
        resetApp.addEventListener('click', () => {
            logout().then(() => {
                showSuccessModal("Toutes vos données locales ont été supprimées", "Application réinitialisée", () => {
                    location.href = "/"
                })
            })
        })

        //----------------------------------
        // Notifications
        //----------------------------------

        let notificationCheck = document.getElementById('notificationCheck')

        // we have permission, check switchbox
        IDB.get("notificationsEnabled").then(enabled => {
            notificationCheck.checked = !(typeof enabled === "undefined" || !enabled)
        })

        // on switch click
        notificationCheck.addEventListener('click', (e) => {
            e.preventDefault()
            subscribeToNotifications().then(permission => {
                if (permission === "granted") {
                    notificationCheck.checked = !notificationCheck.checked
                    notificationCheck.checked ? IDB.set("notificationsEnabled", true) : IDB.set("notificationsEnabled", false)
                } else if (permission === "denied") {
                    showCenterToast("Les notifications sont bloquées au niveau du système ou du navigateur", 2000)
                    notificationCheck.checked = false
                    IDB.set("notificationsEnabled", false)
                } else {
                    notificationCheck.checked = false
                    IDB.set("notificationsEnabled", false)
                }
            })

        })

    }
})