document.addEventListener('DOMContentLoaded', () => {
  // setTimeout(async () => { // dont show before preloader disappears
  //   let eventName = document.getElementById('eventName') ? document.getElementById('eventName').value : null
  //   let appTitle = document.getElementById('appTitle') ? document.getElementById('appTitle').value : null
  //   let isBorne = localStorage.getItem("isBorne") === "1"

  //   // skips
  //   const skipHomeIntro = await IDB.get('skipHomeIntro') || false
  //   const skipScheduleIntro = await IDB.get('skipScheduleIntro') || false

  //   // global options
  //   let opts = {
  //     nextLabel: 'Suivant',
  //     prevLabel: 'Précédent',
  //     doneLabel: 'Terminé'
  //   }

  //   // Home intro
  //   if (!skipHomeIntro && !isBorne) {
  //     if (document.querySelector('#appCapsule.home')) {
  //       let homeIntro = introJs().setOptions({
  //         ...opts,
  //         ...{
  //           steps: [
  //             {
  //               title: 'Visite guidée',
  //               intro: `Merci d'avoir installé l'application ${appTitle} !<br>Cliquez sur <strong>Suivant</strong> pour une visite guidée, ou fermez cette fenêtre pour passer.`
  //             },
  //             {
  //               title: 'Le festival',
  //               element: document.querySelector('.introHome'),
  //               intro: "Découvrez le festival en choisissant l'une de ces options"
  //             },
  //             {
  //               title: 'Vos billets',
  //               element: document.querySelector('.introTickets'),
  //               intro: 'Importez ici vos billets pour y accéder à tout moment, même sans connexion Internet'
  //             },
  //             {
  //               title: 'Votre programme',
  //               element: document.querySelector('.introSchedule'),
  //               intro: 'Consultez ici les activités du programme que vous aurez ajouté en favori'
  //             },
  //             {
  //               title: 'Vos inscriptions',
  //               element: document.querySelector('.introRegistrations'),
  //               intro: 'Consultez ici les animations, ateliers ou séances de dédicaces auxquelles vous êtes inscrit'
  //             },
  //             {
  //               title: "C'est tout !",
  //               intro: `Bonne visite, et amusez-vous bien à la ${appTitle} ✨`
  //             }
  //           ]
  //         }
  //       })
  //         .oncomplete(() => { IDB.set('skipHomeIntro', true) })
  //         .onexit(() => { IDB.set('skipHomeIntro', true) })
  //         .start()

  //     }
  //   }

  //   // Schedule intro
  //   if (!skipScheduleIntro && !isBorne) {
      
  //     if (document.querySelector('#appCapsule.schedule')) {
  //       introJs().setOptions({
  //         ...opts,
  //         ...{
  //           showBullets: false,
  //           steps: [
  //             {
  //               title: 'Navigation',
  //               element: document.querySelector('.introSchedule'),
  //               intro: "Choisissez le programme que vous souhaitez afficher en cliquant ici"
  //             },
  //             {
  //               title: "Actions",
  //               element: document.querySelectorAll('.item-event')[0],
  //               intro: "Cliquez sur un événement pour vous inscrire ou pour l'ajouter en favori, vous recevrez des notifications à son sujet"
  //             },
  //             {
  //               title: "Favoris",
  //               element: document.querySelector('.introHeader'),
  //               intro: "Accédez rapidement à vos favoris en cliquant ici"
  //             }
  //           ]
  //         }
  //       })
  //         .oncomplete(() => { IDB.set('skipScheduleIntro', true) })
  //         .onexit(() => { IDB.set('skipScheduleIntro', true) })
  //         .start()
  //     }
  //   }
  // }, 700)
})
