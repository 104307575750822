// URLs where login is not forced
const noLogin = [
  '/totem',
  '/login',
  '/signup',
  '/dashboard',
  '/register',
  '/registration',
  '/registrations',
  '/install'
]

// URLs where user is not prompted to install
const noInstall = [
  '/install',
  '/totem',
  '/register',
  '/registration',
  '/registrations'
]

let skipLogin = (noLogin.filter(r => location.href.indexOf(r) > -1).length !== 0)
// let skipInstall = (noInstall.filter(r => location.href.indexOf(r) > -1).length !== 0)

if (!skipLogin) {

  // Check if user is logged in
  // IDB.get('user').then(user => {

  //   // User is logged in
  //   if (user) {
  //     // check if logged in serverside every hour
  //     let sync = localStorage.getItem("sync")
  //     if (sync) {
  //       let now = new Date();
  //       let then = new Date(sync)
  //       let anHour = 60 * 60 * 1000
  //       let diff = now - then
  //       if (diff > anHour) {
  //         const login = window.login
  //         login(user).then(data => {
  //           if (data) {
  //             localStorage.setItem("sync", new Date().toString())
  //             return IDB.set('user', data) // save current user to IndexedDB
  //           }
  //         }).catch(err => {
  //           IDB.del('user').then(() => {
  //             location.href = "/login"
  //           })
  //         })
  //       }
  //     } else {
  //       localStorage.setItem("sync", new Date().toString())
  //       login(user).then(data => {
  //         if (data) {
  //           return IDB.set('user', data) // save current user to IndexedDB
  //         }
  //       }).catch(err => {
  //         IDB.del('user').then(() => {
  //           location.href = "/login"
  //         })
  //       })
  //     }
  //     // Check for updates
  //     //...
  //   } else {
  //     // User is not logged in
  //     // redirect to login
  //     console.log("User is not logged in, redirect to login")
  //     location.href = "/login"
  //   }
  // })

}


window.addEventListener('DOMContentLoaded', () => {

  // DISABLED UNTIL YOU FIGURE OUT HOW TO CACHE ONLINE VERSIONS OF PAGES OR CACHE AFTER USER HAS LOGGED IN
  // if (!skipLogin) {
  //   if (document.querySelector('body').classList.contains('logged-out')) {
  //     location.href = "/login"
  //   }
  // }

  // if (!skipInstall) {
  //   // Check if app is installed
  //   let displayMode = 'browser tab';
  //   if (window.matchMedia('(display-mode: standalone)').matches) {
  //     displayMode = 'standalone';
  //   }

  //   // App is not installed, prompt user to install or continue in browser
  //   if (displayMode !== 'standalone') {
  //     let skipInstall = localStorage.getItem("skipInstall") === "1"
  //     if (!skipInstall) {
  //       let platformModal = document.getElementById('platformModal') ? new bootstrap.Modal(document.getElementById('platformModal')) : null;
  //       let continueBrowser = document.getElementById('continueBrowser')

  //       continueBrowser.addEventListener('click', () => {
  //         localStorage.setItem('skipInstall', 1)
  //         platformModal.hide()
  //       })

  //       platformModal.show()
  //     }
  //   }
  // }
})


