

import { reloadCache } from "./_swController";
// declare exports
let errorModal, errorTitle, errorMessage, successModal, successTitle, successMessage, scanResultModal, scanResultModalMessage;


//-----------------------------------------------------------------------
// Online / Offline behavior
//-----------------------------------------------------------------------
function onlineBehavior() {
    console.log("Client is online")
    document.querySelector('body').classList.remove('is-offline')
    toastbox('onlineToast', 3000)
    IDB.del('offline')
}

function offlineBehavior() {
    console.log("Client is offline")
    document.querySelector('body').classList.add('is-offline')
    toastbox('offlineToast', 3000)
    IDB.set('offline', 1)
}

// Check with event listener if online or offline
window.addEventListener('online', onlineBehavior)
window.addEventListener('offline', offlineBehavior)

//-----------------------------------------------------------------------
// MobileKit Overrides
//-----------------------------------------------------------------------
OnlineText = `<span>Connexion à internet rétablie</span>`
OfflineText = `<span>Connexion à internet perdue</span>`

window.removeEventListener('online', onlineMode);
window.removeEventListener('offline', offlineMode);

const showErrorModal = function (message, title = null, cb = null) {
    title ? errorTitle.innerText = title : errorTitle.innerText = "Erreur"
    cb ? document.getElementById('errorModal').addEventListener('hide.bs.modal', cb) : null;
    errorMessage.innerText = message
    errorModal.show()
}

const showSuccessModal = function (message, title = null, cb = null) {
    //title is provided
    title ? successTitle.innerText = title : successTitle.innerText = "Terminé"
    // callback is provided, bind on modal hide
    cb ? document.getElementById('successModal').addEventListener('hide.bs.modal', cb) : null;

    successMessage.innerText = message
    successModal.show()
}

const showScanResultModal = function (message, cb = null) {
    // callback is provided, bind on modal hide
    cb ? document.getElementById('scanResultModal').addEventListener('hide.bs.modal', cb) : null;

    scanResultModalMessage.innerHTML = message
    scanResultModal.show()
}

const showCenterToast = function (message, time, icon = null, iconColor) {
    document.querySelector('#centerToast .toast-icon').classList.remove('show')
    document.querySelector('#centerToast div.text').innerText = message
    if (icon) {
        document.querySelector('#centerToast .toast-icon').name = icon
        document.querySelector('#centerToast .toast-icon').classList.add('show')
        iconColor ? document.querySelector('#centerToast .toast-icon').classList.add(iconColor) : null
    }
    toastbox('centerToast', time)
}

const showErrorToast = function (message, time = null) {
    document.querySelector('#errorToast div.text').innerText = message
    toastbox('errorToast', time)
}

const showSuccessToast = function (message, time = null) {
    document.querySelector('#successToast div.text').innerText = message
    toastbox('successToast', time)
}

const showActionToast = function (message, actionTitle, actionUrl, timeout) {
    document.querySelector('#actionToast div.text').innerText = message
    document.querySelector('#actionToast a.at-btn').innerText = actionTitle
    document.querySelector('#actionToast a.at-btn').href = actionUrl
    toastbox('actionToast', timeout)
}


//-----------------------------------------------------------------------
// DOM ready
//-----------------------------------------------------------------------
document.addEventListener('DOMContentLoaded', () => {

    // TODO : detect if installed or redirect to store (or message on PC)

    //-----------------------------------------------------------------------
    // OS Detection
    //-----------------------------------------------------------------------
    var osDetection = navigator.userAgent || navigator.vendor || window.opera;
    var windowsPhoneDetection = /windows phone/i.test(osDetection);
    var androidDetection = /android|Android/i.test(osDetection);
    var iosDetection = /iPad|iPhone|iPod/.test(osDetection) && !window.MSStream;

    var detectionWindowsPhone = document.querySelectorAll(".windowsphone-detection");
    var detectionAndroid = document.querySelectorAll(".android-detection");
    var detectioniOS = document.querySelectorAll(".ios-detection");
    var detectionNone = document.querySelectorAll(".non-mobile-detection");

    if (windowsPhoneDetection) {
        // Windows Phone Detected
        detectionWindowsPhone.forEach(function (el) {
            el.classList.add("is-active");
        })
    }
    else if (androidDetection) {
        // Android Detected
        detectionAndroid.forEach(function (el) {
            el.classList.add("is-active");
        })
    }
    else if (iosDetection) {
        // iOS Detected
        detectioniOS.forEach(function (el) {
            el.classList.add("is-active");
        })
    }
    else {
        // Non-Mobile Detected
        detectionNone.forEach(function (el) {
            el.classList.add("is-active");
        })

    }

    // those are exported, declared at the top
    errorModal = document.getElementById('errorModal') ? new bootstrap.Modal(document.getElementById('errorModal')) : null;
    errorTitle = document.getElementById('errorModal').querySelector('.error-title')
    errorMessage = document.getElementById('errorModal').querySelector('.error-message')
    successModal = document.getElementById('successModal') ? new bootstrap.Modal(document.getElementById('successModal')) : null;
    successTitle = document.getElementById('successModal').querySelector('.success-title')
    successMessage = document.getElementById('successModal').querySelector('.success-message')
    scanResultModal = document.getElementById('scanResultModal') ? new bootstrap.Modal(document.getElementById('scanResultModal')) : null;
    scanResultModalMessage = document.getElementById('scanResultModal').querySelector('.scan-message')
    // those we dont export
    let adminModal = document.getElementById('adminModal') ? new bootstrap.Modal(document.getElementById('adminModal')) : null;
    let goAdminModal = document.getElementById('goAdminModal') ? new bootstrap.Modal(document.getElementById('goAdminModal')) : null;

    //-----------------------------------------------------------------------
    // Base
    //-----------------------------------------------------------------------

    const goBorne = document.getElementById('goBorne')
    const leaveBorne = document.getElementById('leaveBorne')
    const reloadCacheBtn = document.getElementById('reloadCache')
    const superSecret = document.getElementById('superSecret')

    if (superSecret) {
        let secretCount = 0;
        superSecret.addEventListener('click', () => {
            secretCount++
            if (secretCount > 5) {
                location.href = "/admin"
            }
        })
    }


    const adminLoginForm = document.getElementById('adminLogin')

    if (adminLoginForm) {
        adminLoginForm.addEventListener('submit', e => {
            e.preventDefault()

            const data = Object.fromEntries(new FormData(adminLoginForm).entries())

            fetch("/admin/password", {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            }).then(function (response) {
                if (response.status === 200) {
                    location.href = "/admin"
                } else {
                    reject("Une erreur inconnue s'est produite, réessayez plus tard")
                }
            }).catch(err => {
                console.error(err)
            })

        })
    }
    if (goBorne) {
        goBorne.addEventListener('click', () => {
            localStorage.setItem('isBorne', 1)
            location.href = "/borne"
        })
    }

    if (leaveBorne) {
        leaveBorne.addEventListener('click', () => {
            localStorage.removeItem('isBorne')
            location.href = "/leaveborne"
        })
    }


    if (reloadCacheBtn) {
        reloadCacheBtn.addEventListener('click', () => {
            reloadCache()
        })
    }
    // TODO : juste statut ZEN

    //-----------------------------------------------------------------------
    // Global modals
    //-----------------------------------------------------------------------
    if (adminModal) {
        document.querySelector('#adminModal form').addEventListener('submit', e => {
            e.preventDefault()
            let password = document.getElementById('adminPassword').value
            if (password.length) {
                fetch("/admin/password", {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify({ password: password })
                }).then(response => {
                    if (response.status === 200) {
                        IDB.set("isAdmin", true).then(() => {
                            // location.href = "/admin"
                            console.log("Now admin")
                        })
                    } else {
                        showErrorModal("Mot de passe incorrect", "C'est non")
                    }
                })
                adminModal.hide()
            }
        })

    }

    //-----------------------------------------------------------------------
    // Misc
    //-----------------------------------------------------------------------

    // Fetch all the forms we want to apply custom Bootstrap validation styles to
    var forms = document.querySelectorAll('.needs-validation')

    // Loop over them and prevent submission
    Array.prototype.slice.call(forms)
        .forEach(function (form) {
            form.addEventListener('submit', function (event) {
                if (!form.checkValidity()) {
                    event.preventDefault()
                    event.stopPropagation()
                }

                form.classList.add('was-validated')
            }, false)
        })


    /*** print headers */

    function printHandler() {
        window.print();
    }

    const printButtons = document.querySelectorAll('.print')
    if (printButtons) {

        // event list
        const eventElementList = [].slice.call(document.querySelectorAll('.print'))
        //unbind
        eventElementList.map(el => {
            el.removeEventListener('click', printHandler)
        })
        //bind
        eventElementList.map(el => {
            el.addEventListener('click', printHandler)
        })
    }


    /***
     * timer
     */


    let timerModalEl = document.getElementById('timerModal')
    let home = document.querySelector('div#appCapsule.home')
    let isBorne = document.querySelector('body.is-borne')

    if (timerModalEl && isBorne && home === null) {

        // get time
        let time = 10;
        if (!Number.isNaN(parseInt(timerModalEl.dataset.time))) {
            time = parseInt(timerModalEl.dataset.time);
        }

        //modal 
        let timerModal = new bootstrap.Modal(timerModalEl)

        // interval
        let timeSpent = 0;
        let timerInterval = setInterval(function () {
            timeSpent++;
            if (timeSpent >= time) {
                timerModal.show()
            }
            if (timeSpent >= time + 10) {
                location.href = "/"
            }
        }, 1000)

        //events

        timerModalEl.addEventListener('hide.bs.modal', function () {
            timeSpent = 0;
        });

        ['interact', 'click', 'touchstart', 'scroll'].forEach(function (eventName) {
            document.body.addEventListener(eventName, () => {
                console.log("reset timer")
                timeSpent = 0;
            })
        })
    }

})

export { showErrorModal, showSuccessModal, showCenterToast, showActionToast, showErrorToast, showSuccessToast, showScanResultModal }