document.addEventListener('DOMContentLoaded', async () => {

    let promoPage = document.querySelector('#appCapsule.promos')
    let qrcode = document.querySelector('canvas#promoQr')
    const promoActionSheet = document.getElementById('promoActionSheet') ? new bootstrap.Offcanvas(document.getElementById('promoActionSheet')) : null

    if (promoPage) {
        // promo list
        const promoElementList = [].slice.call(document.querySelectorAll('li a.promo-item'))

        //bind
        promoElementList.map(el => {
            el.addEventListener('click', () => {

                // if data voucher -> show qr
                // otherwise ajax call
                // wrap all in promise

                new Promise((resolve, reject) => {

                    if (el.classList.contains("disabled")) {
                        console.log("voucher used")
                        reject()
                    }

                    if (el.dataset.voucher) {
                        console.log("hasvoucher")
                        if (el.dataset.voucher.length) {
                            resolve(el.dataset.voucher)
                        } else {
                            reject("AAAAAAAAAAAA")
                        }
                    } else {
                        let promoId = el.dataset.id
                        fetch("/ajax/createVoucher", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ promoId: promoId }),
                        }).then(function (response) {
                            if (response.status === 200) {
                                response.json().then(data => {
                                    resolve(data.voucher)
                                })
                            } else {
                                reject("Une erreur inconnue s'est produite, réessayez plus tard")
                            }
                        }).catch(err => {
                            console.error(err)
                            reject("Aucune réponse du serveur, réessayez plus tard")
                        })
                    }

                }).then(voucher => {
                    return QRCode.toCanvas(qrcode, voucher)
                }).then(() => {
                    // show action sheet
                    promoActionSheet.show()
                }).catch(err => {
                    console.error(err)
                })

            })
        })

    }
    // Fin page "Mes billets"
})