
import { showErrorModal, showSuccessModal, showCenterToast } from "./_global";
import { reloadCache } from './_swController'

// URLs where user doesnt have to be logged in
const noLoginRequired = [
    '/totem',
    '/login',
    '/signup',
    '/dashboard',
    '/register',
    '/registration',
    '/registrations'
]

/**
 * Login user
 * @param {email, password, accessToken} data 
 * @returns a Promise resolving a user or null
 */
const login = function (data) {
    return new Promise((resolve, reject) => {
        fetch("/login", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then(function (response) {
            if (response.status === 200) {
                response.json().then(user => {
                    localStorage.setItem("loggedIn", "1")
                    console.log("Login in...")
                    reloadCache()
                    resolve(user)
                })
            } else if (response.status === 403) {
                reject("Mauvaise adresse e-mail et/ou mot de passe")
            } else {
                reject("Une erreur inconnue s'est produite, réessayez plus tard")
            }
        }).catch(err => {
            console.error(err)
            reject("Aucune réponse du serveur, réessayez plus tard")
        })
    })
}

const logout = function () {
    return new Promise((resolve, reject) => {
        fetch("/logout", {
            method: "POST"
        }).then(function () {
            IDB.clear().then(() => {
                localStorage.setItem("loggedIn", "0")
                console.log("Login out...")
                reloadCache()
                resolve()
            })
        }).catch(err => {
            console.error(err)
            reject("Aucune réponse du serveur, réessayez plus tard")
        })
    })
}
window.login = login
window.logout = logout

/**
 * Signup new user
 * @param {firstname, lastname, email, password} data 
 */
const signup = function (data) {

    return new Promise((resolve, reject) => {
        fetch("/signup", {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify(data),
        }).then(function (response) {
            if (response.status === 200) {
                response.json().then(user => {
                    resolve(user)
                })
            } else if (response.status === 400) {
                response.text().then(text => {
                    reject(text)
                })
            } else {
                reject("Une erreur inconnue s'est produite, réessayez plus tard")
            }
        }).catch(err => {
            console.error(err)
            reject("Aucune réponse du serveur, réessayez plus tard")
        })
    })

}

document.addEventListener('DOMContentLoaded', async () => {
    const loginPage = document.querySelector('#appCapsule.login')
    const signupPage = document.querySelector('#appCapsule.signup')
    const loginForm = document.getElementById('login')
    const signupForm = document.getElementById('signup')
    const logoutEl = document.getElementById('logout')


    /**
     * Disable login form
     */
    const disableLoginForm = function () {
        let inputs = loginForm.querySelectorAll('input, button');
        [].slice.call(inputs).forEach(input => {
            input.disabled = true
            input.classList.add('disabled')
        })
    }

    if (logoutEl) {
        logoutEl.addEventListener('click', () => {
            logout().then(() => {
                location.href = "/login"
            })
        })
    }

    // Login page behavior
    if (loginPage) {

        /**
         * Auto-login with accessToken
         */

        IDB.get('user').then(user => {
            if (user && user.accessToken) {
                // disable form
                disableLoginForm()
                showCenterToast("Connexion automatique...", 1000, "log-in-outline")
                login(user)
                    .then(setTimeout(() => {
                        console.log("Logged in with accessToken")
                        location.href = "/"
                    }, 1000))
            }
        })

        // Login form behavior
        loginForm.addEventListener('submit', function (e) {

            // validation
            if (!loginForm.checkValidity()) {
                e.preventDefault()
                e.stopPropagation()
            } else {
                e.preventDefault()

                const data = Object.fromEntries(new FormData(loginForm).entries())
                if (data.email.length > 0 && data.password.length > 0) {
                    login(data).then(user => {
                        if (user) {
                            disableLoginForm()
                            return IDB.set('user', user) // save current user to IndexedDB
                        }
                    })
                        .then(() => {
                            showCenterToast("Connexion réussie", 1500, "checkmark-circle")
                            setTimeout(() => {
                                console.log("Login form sent")
                                location.href = "/"
                            }, 1500)
                        })
                        .catch(err => {
                            showErrorModal(err, "Erreur")
                        })
                }
            }
        })
    }

    // Signup page behavior
    if (signupPage) {

        signupForm.addEventListener('submit', function (e) {

            // validation
            if (!signupForm.checkValidity()) {
                e.preventDefault()
                e.stopPropagation()
            } else {
                e.preventDefault()

                const data = Object.fromEntries(new FormData(signupForm).entries())
                if (data.firstname.length && data.lastname.length && data.email.length && data.password.length) {
                    signup(data).then(user => {
                        if (user) {
                            IDB.set('user', user) // save current user to IndexedDB
                            showCenterToast("Votre compte a bien été créé", 1500, "checkmark-circle")
                            setTimeout(() => {
                                console.log("Register form sent")
                                location.href = "/"
                            }, 1500)
                        }
                    }).catch(err => {
                        showErrorModal(err, "Erreur")
                    })
                }
            }
        })
    }

});