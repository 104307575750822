document.addEventListener('DOMContentLoaded', () => {
    //-----------------------------------------------------------------------
    // Plan
    //-----------------------------------------------------------------------
    let planPage = document.querySelector('#appCapsule.plan')

    let initialY = window.innerHeight ? window.innerHeight / 4 : 100;
    if (planPage) {
        let planEl = document.getElementById('plan')
        panzoom(planEl, {
            maxZoom: 1,
            minZoom: 0.1,
            initialZoom: 0.2,
            initialX: 0,
            initialY: initialY - 250
        })
    }
})