import { showCenterToast, showActionToast } from "./_global"

document.addEventListener('DOMContentLoaded', async () => {

    let isBorne = document.querySelector('body.is-borne')
    //-----------------------------------------------------------------------
    // Schedule
    //-----------------------------------------------------------------------
    let schedulePage = document.querySelector('#appCapsule.schedule')
    let bookmarksPage = document.querySelector('#appCapsule.bookmarks')
    let registrationsPage = document.querySelector('#appCapsule.registrations')
    let totemEventsPage = document.querySelector('#appCapsule.totem-events')
    let selectedSchedule = document.getElementById('selectedSchedule') // button
    let selectScheduleListItems = [].slice.call(document.querySelectorAll('a.selectSchedule'))
    let bookmarkedEvents = await IDB.get("bookmarkedEvents") || []

    if (schedulePage || bookmarksPage || totemEventsPage || registrationsPage) {
        const scheduleActionSheet = document.getElementById('eventActionSheet') ? new bootstrap.Offcanvas(document.getElementById('eventActionSheet')) : null

        // mark bookmarked events
        // markBookmarkedEvents()

        // mark registered events
        markRegisteredEvents()

        // bind click events
        bindEvents()

        // select default schedule
        selectSchedule(1)

        const actionEventName = document.getElementById("actionEventName")
        const actionEventDate = document.getElementById("actionEventDate")
        const actionEventTime = document.getElementById("actionEventTime")
        const actionEventPlace = document.getElementById("actionEventPlace")
        const actionEventDesc = document.getElementById("actionEventDesc")
        const actionEventCapacity = document.getElementById("actionEventCapacity")
        // const actionAddBookmark = document.getElementById('actionAddBookmark')
        // const actionRemoveBookmark = document.getElementById('actionRemoveBookmark')
        const actionRegister = document.getElementById('actionRegister')
        const actionRegistered = document.getElementById('actionRegistered')
        const actionFull = document.getElementById('actionFull')
        const actionNeedsRegistration = document.getElementById('actionNeedsRegistration')

        // click on add event to bookmark
        // actionAddBookmark.addEventListener('click', () => {
        //     let event = JSON.parse(scheduleActionSheet._element.dataset.event)
        //     bookmarkedEvents.push(event)
        //     IDB.set('bookmarkedEvents', bookmarkedEvents)
        //     showRemoveBookmark()
        //     markBookmarkedEvents()
        //     setTimeout(() => {
        //         showActionToast("Ajouté à Mes favoris", "Voir la liste", "/bookmarks", 3000)
        //     }, 100)
        // })

        // // click on remove event from bookmark
        // actionRemoveBookmark.addEventListener('click', () => {
        //     let event = JSON.parse(scheduleActionSheet._element.dataset.event)
        //     // remove bookmark where id is not current event id
        //     bookmarkedEvents = bookmarkedEvents.filter(b => b.id !== event.id)
        //     IDB.set('bookmarkedEvents', bookmarkedEvents)
        //     showAddBookmark()
        //     markBookmarkedEvents()
        //     setTimeout(() => {
        //         showActionToast("Retiré de Mes favoris", "Voir la liste", "/bookmarks", 3000)
        //     }, 100)


        //     // on bookmarks page we also delete the event from the list and reload when all bookmarks are cleared
        //     if (bookmarksPage) {
        //         if (bookmarkedEvents.length <= 0) {
        //             location.reload()
        //         }
        //         document.querySelector(`.item-event-${event.id}`).remove()
        //     }
        // })

        // click on register
        actionRegister.addEventListener('click', () => {
            let event = JSON.parse(scheduleActionSheet._element.dataset.event)
            location.href = "/register/scan/" + event.id
        })

        // select schedule from schedule modal
        selectScheduleListItems.map(el => {
            el.addEventListener('click', () => {
                selectedSchedule.innerText = el.dataset.scheduleName
                selectSchedule(el.dataset.scheduleId)
            })
        })

        //-----------------------------------------------------------------------
        // Bookmarks page
        //-----------------------------------------------------------------------
        // if (bookmarksPage) {
        //     let noBookmarksEl = document.getElementById('noBookmarks')
        //     let hasBookmarksEl = document.getElementById('hasBookmarks')

        //     if (bookmarkedEvents.length) {
        //         let eventTpl = document.getElementById('eventTpl').cloneNode(true)
        //         let eventList = document.getElementById('eventList')

        //         // sort by start time
        //         bookmarkedEvents.sort((a, b) => new Date(a.start).getTime() > new Date(b.start).getTime() ? 1 : -1)

        //         bookmarkedEvents.forEach(function (event) {
        //             // create DOM elements for each event
        //             let el = eventTpl.cloneNode(true)
        //             el.classList.add(`item-event-${event.id}`, 'show', 'bookmarked')
        //             el.dataset.eventJson = JSON.stringify(event)
        //             event.registered ? el.classList.add('registered') : null
        //             event.name ? el.querySelector('.event-name').innerText = event.name : null;
        //             event.subtitle ? el.querySelector('.event-subtitle').innerText = event.subtitle : null;
        //             event.start ? el.querySelector('.event-day').innerText = dayjs(event.start).format('dddd D MMMM').toLowerCase() : null;
        //             event.schedule && event.schedule.name ? el.querySelector('.event-place').innerText = event.schedule.name : null;
        //             event.start && event.end ? el.querySelector('.event-time').innerText = `${dayjs(event.start).format('HH:mm')} - ${dayjs(event.end).format('HH:mm')}` : null;
        //             el.id = ""
        //             el.style = ""
        //             eventList.append(el)
        //         })

        //         bindEvents()

        //         hasBookmarksEl.classList.add('show')
        //     } else {
        //         noBookmarksEl.classList.add('show')
        //     }
        // }

        //-----------------------------------------------------------------------
        // Registrations page
        //-----------------------------------------------------------------------
        if (registrationsPage) {
            let noBookmarksEl = document.getElementById('noBookmarks')
            let hasBookmarksEl = document.getElementById('hasBookmarks')

            // keep only registered events
            let registeredEvents = bookmarkedEvents.filter(b => b.registered === true) // get registered events (always bookmarked)

            // fetch from server and refresh if needed
            // fetch('/ajax/registrations', {
            //     method: "GET",
            //     headers: {
            //         "Content-Type": "application/json",
            //     }
            // }).then(function (response) {
            //     if (response.status === 200) {
            //         response.json().then(data => {

            //             let events = data
            //             // get bookmarked ids
            //             let bookmarked = bookmarkedEvents.map(b => b.id)

            //             // save events to local bookmarks if not exist, then reload
            //             events.forEach(event => {
            //                 if (bookmarked.indexOf(event.id) === -1) {
            //                     bookmarkedEvents.push(event)
            //                     IDB.set('bookmarkedEvents', bookmarkedEvents).then(() => {
            //                         location.reload()
            //                     })
            //                 }
            //             })
            //         })
            //     } else {
            //         throw new Error("Une erreur inconnue s'est produite, réessayez plus tard")
            //     }
            // }).catch(err => {
            //     console.error(err)
            // })

            if (registeredEvents.length) {
                let eventTpl = document.getElementById('eventTpl').cloneNode(true)
                let eventList = document.getElementById('eventList')

                // sort by start time
                registeredEvents.sort((a, b) => new Date(a.start).getTime() > new Date(b.start).getTime() ? 1 : -1)

                registeredEvents.forEach(function (event) {
                    // create DOM elements for each event
                    let el = eventTpl.cloneNode(true)
                    el.classList.add(`item-event-${event.id}`, 'show', 'registered')
                    el.dataset.eventJson = JSON.stringify(event)
                    event.name ? el.querySelector('.event-name').innerText = event.name : null;
                    event.subtitle ? el.querySelector('.event-subtitle').innerText = event.subtitle : null;
                    event.start ? el.querySelector('.event-day').innerText = dayjs(event.start).format('dddd D MMMM').toLowerCase() : null;
                    event.schedule.name ? el.querySelector('.event-place').innerText = event.schedule.name : null;
                    event.start && event.end ? el.querySelector('.event-time').innerText = `${dayjs(event.start).format('HH:mm')} - ${dayjs(event.end).format('HH:mm')}` : null;
                    el.id = ""
                    el.style = ""
                    eventList.append(el)
                })

                bindEvents()

                hasBookmarksEl.classList.add('show')
            } else {
                noBookmarksEl.classList.add('show')
            }
        }
        //-----------------------------------------------------------------------
        // Functions
        //-----------------------------------------------------------------------

        function bindEvents() {

            if (isBorne === null) {
                // event list
                const eventElementList = [].slice.call(document.querySelectorAll('li.item-event'))
                //unbind
                eventElementList.map(el => {
                    el.removeEventListener('click', eventClickHandler)
                })
                //bind
                eventElementList.map(el => {
                    el.addEventListener('click', eventClickHandler)
                })
            }

        }

        //-----------------------------------------------------------------------
        // Action Sheet on event click
        //-----------------------------------------------------------------------
        function eventClickHandler(event) {
            let el = this

            if (el.dataset.isadmin) {
                event.preventDefault();
                location.href = `/admin/participants/${el.dataset.eventId}`
            }

            // hide action sheet actions by default
            // hideBookmarkActions()
            hideRegisterActions()



            // update action sheet details
            if (el.dataset.eventJson) {
                let event = JSON.parse(el.dataset.eventJson)


                // event.day = el.dataset.eventDay
                event.needsRegistration ? actionNeedsRegistration.classList.add('show') : actionNeedsRegistration.classList.remove('show')
                actionEventName.innerText = event.name
                actionEventDate.innerText = dayjs(event.start).format('dddd D MMMM').toLowerCase()
                actionEventTime.innerText = `${dayjs(event.start).format('HH:mm')} - ${dayjs(event.end).format('HH:mm')}`
                actionEventPlace.innerText = event.schedule.name
                actionEventDesc.innerHTML = event.description
                actionEventCapacity.innerText = event.isFull ? "Complet" : `${event.participants} / ${event.maxParticipants}`

                //serialize event and add to action sheet dataset
                scheduleActionSheet._element.dataset.event = JSON.stringify(event)

                // check if event is bookmarked
                // get bookmarked event ids
                let bookmarked = bookmarkedEvents.map(b => b.id)
                let registered = bookmarkedEvents.filter(b => b.registered === true).map(b => b.id) // get registered events (always bookmarked)

                // event requires registration
                if (event.needsRegistration) {
                    // user is registered
                    if (registered.indexOf(event.id) !== -1) {
                        let token = bookmarkedEvents.filter(b => b.id === event.id)[0].registration.token
                        // you cant remove an event you partake in from your bookmarks
                        showRegistered(token)
                    } else {
                        if (event.isFull) {
                            showFull()
                        } else {
                            showRegister()
                        }
                    }
                    // event can be bookmarked
                } else {
                    // if current event id is in bookmarked ids, show remove btn
                    if (bookmarked.indexOf(event.id) !== -1) {
                        // showRemoveBookmark()
                    } else { // otherwise show add button
                        // showAddBookmark()
                    }
                }

                if (!event.registerOnApp) {
                    hideRegisterActions()
                }

                // show action sheet
                scheduleActionSheet.show()
            }
        }

        function selectSchedule(id) {
            // hide all
            let shownEvents = [].slice.call(document.querySelectorAll(".item-event.show"))
            let toBeSelected = [].slice.call(document.querySelectorAll(`.item-event.item-schedule-${id}`))

            shownEvents.forEach(function (el) {
                el.classList.remove("show");
            });

            // show selected
            toBeSelected.forEach(function (el) {
                el.classList.add("show");
            });

            window.scrollTo(0, 0);
        }

        // Action sheet buttons
        function showRemoveBookmark() {
            actionAddBookmark.classList.remove('show')
            actionRemoveBookmark.classList.add('show')
        }
        // Action sheet buttons
        function showAddBookmark() {
            actionAddBookmark.classList.add('show')
            actionRemoveBookmark.classList.remove('show')
        }
        // Action sheet buttons
        function showRegistered(token) {
            actionRegistered.classList.add('show')
            actionRegistered.href = `/registration/${token}`
            actionRegister.classList.remove('show')
            actionFull.classList.remove('show')
        }
        // Action sheet buttons
        function showFull() {
            actionFull.classList.add('show')
            actionRegistered.classList.remove('show')
            actionRegister.classList.remove('show')
        }
        // Action sheet buttons
        function showRegister() {
            actionRegistered.classList.remove('show')
            actionFull.classList.remove('show')
            actionRegister.classList.add('show')
        }
        // Action sheet buttons
        function hideBookmarkActions() {
            actionAddBookmark.classList.remove('show')
            actionRemoveBookmark.classList.remove('show')
        }
        // Action sheet buttons
        function hideRegisterActions() {
            actionRegistered.classList.remove('show')
            actionRegister.classList.remove('show')
        }

        // function markBookmarkedEvents() {
        //     // clear all bookmarked
        //     var events = document.querySelectorAll(".item-event.bookmarked");
        //     [].forEach.call(events, function (el) {
        //         el.classList.remove("bookmarked");
        //     });

        //     // redo
        //     bookmarkedEvents.forEach(bookmarked => {
        //         document.querySelector(`.item-event-${bookmarked.id}`) ? document.querySelector(`.item-event-${bookmarked.id}`).classList.add('bookmarked') : null
        //     });
        // }

        function markRegisteredEvents() {
            // clear all registered
            var events = document.querySelectorAll(".item-event.registered");
            [].forEach.call(events, function (el) {
                el.classList.remove("registered");
            });

            // redo
            bookmarkedEvents.forEach(bookmarked => {
                if (bookmarked.registered) {
                    document.querySelector(`.item-event-${bookmarked.id}`) ? document.querySelector(`.item-event-${bookmarked.id}`).classList.add('registered') : null
                }
            });
        }

    }

})