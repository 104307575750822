
import { showErrorModal, showSuccessModal } from "./_global";
import { reloadCache } from './_swController'

document.addEventListener('DOMContentLoaded', async () => {
    //-----------------------------------------------------------------------
    // Mes billets
    //-----------------------------------------------------------------------
    let ticketPage = document.querySelector('#appCapsule.tickets')
    if (ticketPage) {

        const tickets = await IDB.get("tickets") // local tickets

        let noTicketsEl = document.getElementById('noTickets')
        let hasTicketsEl = document.getElementById('hasTickets')
        let loginModal = document.getElementById('loginModal') ? new bootstrap.Modal(document.getElementById('loginModal')) : null;
        let fetchingModal = document.getElementById('fetchingModal') ? new bootstrap.Modal(document.getElementById('fetchingModal')) : null;
        let fetchedModal = document.getElementById('fetchedModal') ? new bootstrap.Modal(document.getElementById('fetchedModal')) : null;
        let fullScreen = document.getElementById('fullScreen')


        // display "successfully fetched tickets" modal
        const showFetchedModal = function () {
            IDB.set('scrollToEnd', 1)
            fetchingModal.hide()
            fetchedModal.show()
        }

        // display ticket qrcode in fullscreen
        const showFullScreen = function (qrCode) {
            if (fullScreen) {
                fullScreen.querySelector('img.ticket-image').src = qrCode
                fullScreen.classList.add('show')
            }
        }

        if (fullScreen) {
            fullScreen.addEventListener('click', function () {
                console.log(this.classList.remove('show'))
            })
        }

        //-----------------------------------------------------------------------
        // User has tickets
        //-----------------------------------------------------------------------

        if (tickets) {

            // setup carousel
            let ticketListEl = document.getElementById('ticketList')
            let ticketSplide = new Splide(ticketListEl, {
                perPage: 1,
                rewind: false,
                type: "slide",
                gap: 16,
                padding: 32,
                arrows: false,
                pagination: false
            }).mount();

            // iterate over tickets, build slides
            if (ticketListEl) {
                let ticketTpl = document.getElementById('ticketTpl').cloneNode(true)

                tickets.forEach(function (ticket) {
                    if (ticket) {
                        let el = ticketTpl.cloneNode(true)
                        el.style = ""
                        ticket.type ? el.querySelector('span.ticket-type').innerText = ticket.type : null;
                        ticket.details ? el.querySelector('span.ticket-details').innerText = ticket.details : null;
                        ticket.qrCode ? el.querySelector('img.ticket-image').src = ticket.qrCode : null;
                        ticket.orderDate ? el.querySelector('span.ticket-date').innerText = ticket.orderDate : null;
                        ticket.order ? el.querySelector('span.ticket-order').innerText = ticket.order : null;
                        ticket.price ? el.querySelector('span.ticket-price').innerText = ticket.price : null;
                        ticket.tax ? el.querySelector('span.ticket-tax').innerText = ticket.tax : null;
                        ticket.buyer ? el.querySelector('span.ticket-buyer').innerText = ticket.buyer : null;
                        ticket.buyerEmail ? el.querySelector('span.ticket-buyer-email').innerText = `(${ticket.buyerEmail})` : null;

                        el.remove('tpl')
                        el.dataset.qrCode = ticket.qrCode // init dataset with qrcode img src
                        el.addEventListener('click', () => {
                            showFullScreen(ticket.qrCode) // show fullscreen qrcode on click
                        })
                        ticketSplide.add(el)
                    }
                });

            }

            // if recently added ticket, scroll to last
            if (await IDB.get("scrollToEnd")) {
                IDB.del("scrollToEnd")
                setTimeout(() => {
                    ticketSplide.go(ticketSplide.length - 1)
                }, 500)
            }

            // display carousel
            hasTicketsEl.classList.add('show')
        } else {
            noTicketsEl.classList.add('show')
        }

        //-----------------------------------------------------------------------
        // Add tickets / No tickets added
        //-----------------------------------------------------------------------

        // success modal refresh
        document.getElementById('fetchedModal').addEventListener('hidden.bs.modal', function () {
            location.reload()
        })

        // login form
        let loginForm = document.getElementById('loginForm')
        if (loginForm) {
            loginForm.addEventListener('submit', function (e) {

                // validation
                if (!loginForm.checkValidity()) {
                    e.preventDefault()
                    e.stopPropagation()
                } else {
                    e.preventDefault()
                    let email = document.getElementById('email').value
                    let password = document.getElementById('password').value

                    if (email.length > 0 && password.length > 0) {
                        //hide logging, show loading modal
                        loginModal.hide()
                        fetchingModal.show()

                        //fetch tickets
                        fetch("/ajax/tickets", {
                            method: "POST",
                            headers: {
                                "Content-Type": "application/json",
                            },
                            body: JSON.stringify({ email: email, password: password }),
                        }).then(function (response) {
                            //success
                            if (response.status === 200) {
                                reloadCache()

                                response.json().then(function (data) {

                                    let newTickets = false

                                    // save to local storage
                                    localStorage.setItem("isZen", data.isZen ? 1 : 0)

                                    // if tickets exist, add new ones to existing tickets
                                    if (tickets) {

                                        let remoteTickets = data.tickets
                                        let localTickets = tickets

                                        // compare remote and local
                                        remoteTickets.forEach((rTicket, i) => {
                                            localTickets.forEach((lTicket, j) => {
                                                if (rTicket.qrCode === lTicket.qrCode) {
                                                    delete remoteTickets[i] // remove matches to keep only new tickets
                                                }
                                            })
                                        });

                                        // clear deleted
                                        remoteTickets = remoteTickets.filter(el => el)

                                        // merge local and remote (new tickets only)
                                        IDB.set('tickets', localTickets.concat(remoteTickets))

                                        // no new tickets, error
                                        if (remoteTickets.length <= 0) {
                                            fetchingModal.hide()
                                            errorMessage.innerText = "Nous n'avons pas trouvé de nouveaux billets sur ce compte"
                                            errorModal.show()
                                        } else {
                                            newTickets = true
                                        }
                                    } else {
                                        // no local tickets, add all tickets to local storage
                                        IDB.set('tickets', data.tickets)
                                        newTickets = true
                                    }

                                    // if we added tickets, show success
                                    if (newTickets) {
                                        showFetchedModal()
                                    }

                                })
                            } else {
                                response.text().then(function (text) {
                                    setTimeout(() => {
                                        fetchingModal.hide()
                                        if (text === "nonetwork") {
                                            text = "Cette fonctionnalité nécessite une connexion à Internet"
                                        }
                                        showErrorModal(text)
                                    }, 500)
                                })
                            }
                        }).catch(err => {
                            setTimeout(() => {
                                fetchingModal.hide()
                                showErrorModal("Une erreur inconnue est survenue. Réessayez plus tard.")
                            }, 500)
                        })
                    }
                }
            })
        }

    }
    // Fin page "Mes billets"
})