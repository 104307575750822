document.addEventListener('DOMContentLoaded', () => {
    let vendorsPage = document.querySelector('#appCapsule.vendors')

    if (vendorsPage) {

        // const list = new List(document.querySelector('#vendor-list ul'), {
        //     valueNames: ['vendor-name', 'vendor-products']
        // })

        const options = {
            valueNames: [{ data: ['name', 'products', 'category'] }]
        };
        let lists = []
        document.querySelectorAll('.vendor-list-container').forEach(el => {
            lists.push(new List(el, options))
        })

        const searchInput = document.getElementById('vendorSearch')
        const closeSearch = document.getElementById('closeSearch')

        searchInput.addEventListener('keyup', function () {
            var searchString = searchInput.value.toLowerCase()
            lists.forEach(list => {
                list.search(searchString)
            })
        });

        closeSearch.addEventListener('click', () => {
            searchInput.value = ""
            lists.forEach(list => {
                list.search('')
            })
        })
    }
})